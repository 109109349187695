import React, { useContext, useState, useMemo } from 'react';
import GoBackLink from '../components/go-back-link';
import LinkWithPageTransition from '../components/link-with-page-transition';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import SearchLibrary from '../components/search-library';
import LokaltogContext from '../context/context';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { INewsLibraryPage } from '../types/heartcore.types';
import { dateFormatter, htmlRaw } from '../utils/utils';

const NewsLibraryPage = ({pageContext}:PageData<INewsLibraryPage>) => {
	const context = useContext(LokaltogContext);
	const { meta, parent, modules, headline, bodyText, children, pageSize, searchData } = useContent<INewsLibraryPage>(pageContext, context.rootNodeUrl);
	const [filteredArticles, setFilteredArticles ] = useState(null);

	// This should sort the SearchData in the same way as the children
	const orderedSearchData = useMemo(() => {
		if (!searchData || !children) { return []; }
		const sortedChildren = [...children].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
		const result: any[] = [];
		for (let i = 0; i < sortedChildren.length; i++) {
			const item = sortedChildren[i];
			const el = searchData.searchContent.find(c => c.id === item.id);
			if (el) {
				result.push(el);
			}
		}

		return {
			options: searchData.options,
			searchContent: result,
		};
	}, [searchData]);

	const searchHandler = (filteredItemsIds: string[]) => {
		if(!children) { return; }

		if(!filteredItemsIds) {
			const sorted = children.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
			setFilteredArticles(sorted);
			return;
		}
		const filteredChildren = children.filter(c => filteredItemsIds.includes(c.id)).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
		setFilteredArticles(filteredChildren);
	};

	return (
		<>
			<Meta {...meta} />
			<div className='news-library-page'>
				<div className='box-wide'>
					<GoBackLink parent={parent}></GoBackLink>
					<div className='news-library-page__content'>
						<h1>{headline}</h1>
						<p>{bodyText}</p>
					</div>
					{searchData && <SearchLibrary pageSize={pageSize} searchData={orderedSearchData} callback={searchHandler} >
						<div className='news-library-page__articles'>
							{filteredArticles?.map(article => (
								<section className='news-library-page__article' key={article.id} id={article.id}>
									<LinkWithPageTransition url={article.url}>
										<div className='news-library-page__article__date'>{dateFormatter(article.date || article._updateDate)}</div>
										<h3 className='headline-with-html' dangerouslySetInnerHTML={htmlRaw(article.headline)}></h3>
										<p>{article.introText}</p>
										<span className='link'>{context.tr('Global.ReadMore')}</span>
									</LinkWithPageTransition>
								</section>
							))}
						</div>
					</SearchLibrary>}
				</div>
				<ModulesController modules={modules} />
			</div>
		</>
	);
};

export default NewsLibraryPage;
